import { InjectionKey, Ref } from "vue";
import type { Currency } from "~/helpers/apollo/types";
import { TranslationKey } from "~/helpers/common-helpers";
import type { OpexCategoryBenchmarkData, OpexCategoryDataPointValue } from "~/helpers/portfolio/portfolio-opex-helpers";
import { NamespacePortfolioOpex } from "~/helpers/user-preferences-helpers";

export type TableOpexesPropertyData = {
  resultNormal: number;
  resultTotal: number;
  budgetNormal: number;
  budgetTotal: number;
  area: number;
  areaUnit: string;
  tenancies: number;
};

export type PortfolioPropertyOpexCategoryData = {
  id: string;
  name: string;
  currency: Currency;

  average: TableOpexesPropertyData;
  propertiesData: Map<string, TableOpexesPropertyData>;
};

export const OpexSettingsInjectionKey = Symbol("opexSettingsInjectionKey") as InjectionKey<{ preferencesClone: NamespacePortfolioOpex }>;

export const periodRangeOptions = [
  { id: "yearWhole", name: "PORTFOLIO_VIEW_OPEX_PERIOD_WHOLE_YEAR" },
  { id: "yearRolling", name: "PORTFOLIO_VIEW_OPEX_PERIOD_LATEST_12" },
  { id: "yearToDate", name: "PORTFOLIO_VIEW_OPEX_PERIOD_YEAR_TO_DATE" },
] as { id: NamespacePortfolioOpex["periodRangeType"]; name: TranslationKey }[];

export const settingsTemplateOptions = [
  {
    id: "budgetYTD",
    settings: {
      benchmark: "budget.diff",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearToDate",
    },
  },
  {
    id: "normLatest12",
    settings: {
      benchmark: "average.total",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearRolling",
    },
  },
  { id: "custom", settings: null },
] as const satisfies { id: string; settings: Omit<NamespacePortfolioOpex, "template"> | null }[];
